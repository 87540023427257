<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="设备编号">
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item label="设备类型">
				<el-select v-model="form.deviceTypeCode" placeholder="请选择设备类型">
					<el-option v-for=" ( item , i ) in typeList " :key="i" v-if="i != 6" :label="item.typeName" :value="item.typeCode"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="时间范围">
			  <el-date-picker
			      value-format="yyyy-MM-dd"
			      v-model="date_value"
			      type="daterange"
			      range-separator="至"
			      start-placeholder="开始日期"
			      end-placeholder="结束日期">
			  </el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button @click="device_list()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-download" @click="device_export()" style="margin-right: 10px;">导出</el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-show="listType == 1" v-loading="loading">
			<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
			<el-table-column prop="reportData" label="上报数据" ></el-table-column>
			<el-table-column prop="deviceType" label="设备类型"></el-table-column>
			<el-table-column prop="transferSource" label="传送来源" ></el-table-column>
			<el-table-column prop="transferTarget" label="传送目标" ></el-table-column>
			<el-table-column prop="deviceState" label="设备状态" >
				<template slot-scope="scope">
					<el-tag :type="scope.row.deviceState == null ? 'info' : scope.row.deviceState == 0 ? 'danger' : 'success'" disable-transitions>{{scope.row.deviceState == null ? '—' : scope.row.deviceState == 0 ? '关机' : '开机'}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="protocolVersion" label="主控器协议版本" ></el-table-column>
			<el-table-column prop="programVersion" label="主控器程序版本"></el-table-column>
			<el-table-column prop="programDate" label="主控器程序日期" ></el-table-column>
			<el-table-column prop="model" label="主控器型号" ></el-table-column>
			<el-table-column prop="airType" label="主控器气种" ></el-table-column>
			<el-table-column prop="litres" label="主控器升数"></el-table-column>
			<el-table-column prop="rtc" label="主控器RTC时钟" ></el-table-column>
			<el-table-column prop="createTime" label="上报时间" ></el-table-column>
		</el-table>
		<el-table :data="list" border style="width: 100%" v-show="listType == 0">
			<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
			<el-table-column prop="reportData" label="上报数据" ></el-table-column>
			<el-table-column prop="deviceName" label="设备名称"></el-table-column>
			<el-table-column prop="uuid" label="设备UUID" ></el-table-column>
			<el-table-column prop="code" label="命令码" width="165px">
				<template slot-scope="scope">
					<el-tag :type="scope.row.code == '1f' ? '' : 'success'" disable-transitions>{{ scope.row.code == '1f' ? '设备上电数据上报' :  '无线模块在线数据上报'}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="deviceType" label="设备类型" ></el-table-column>
			<el-table-column prop="ch4State" label="甲烷传感器状态" ></el-table-column>
			<el-table-column prop="ch4Concentration" label="甲烷浓度(单位%LEL)"></el-table-column>
			<el-table-column prop="coState" label="一氧化碳传感器状态" ></el-table-column>
			<el-table-column prop="coConcentration" label="一氧化碳浓度(单位PPM)" ></el-table-column>
			<el-table-column prop="outputStatus" label="阀门状态" >
				<template slot-scope="scope">
					<el-tag :type="scope.row.outputStatus == 0 ? 'danger' : 'success'" disable-transitions>{{ scope.row.outputStatus == 0 ? '关' :  '开'}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="rsrp" label="RSRP"></el-table-column>
			<el-table-column prop="snr" label="SNR" ></el-table-column>
			<el-table-column prop="csq" label="信号强度CSQ" ></el-table-column>
			<el-table-column prop="createTime" label="上报时间" ></el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	var fileDownload = require('js-file-download');
	export default{
		data(){
			return {
				loading:false,
				form : {
					deviceNo : "",
					startPage : 1,
					pageSize : 10,
					deviceTypeCode : 1,
					startDate:"",
					endDate:""
				},
				total : 0,
				list : [],
				typeList:[],
				date_value:'',
				listType:1
			}
		},
		created(){
			this.init_getTypeList()
		},
		mounted(){
			this.device_list();
		},
		methods: {
			init_getTypeList(){
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					}else{
						this.$message({
							type: 'error',
							message: "获取设备类型失败"
						});
					}
				});
			},
			device_list(){
				this.loading = true;
				if( this.form.deviceTypeCode === 0 ){
					this.listType = 0
				}else{
					this.listType = 1
				}
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					this.form.startDate = this.date_value[0];
					this.form.endDate = this.date_value[1];
				}else{
					this.form.startDate = "";
					this.form.endDate = "";
					// this.$message({
					// 	type: 'error',
					// 	message: "请选择时间范围"
					// });
					// return
				}
				this.$api.deviceData_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						for( var i = 0 ; i < this.list.length ; i++ ){
							for( var k in this.list[i] ){
								if( this.list[i][k] == null || this.list[i][k] == "" ){
									this.list[i][k] = "—"
								}
							}
						}
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			changePage( e ){
				this.form.startPage = e;
				this.device_list();
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.device_list();
			},
			device_export(){
				let data = {};
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					data.startDate = this.date_value[0];
					data.endDate = this.date_value[1];
				}else{
					data.startDate = "";
					data.endDate = "";
				}
				data.deviceNo = this.form.deviceNo;
				data.deviceTypeCode = this.form.deviceTypeCode;
				this.$api.deviceData_export( data ).then( res => {
				  fileDownload( res , "设备数据列表.xls" );
				});
			}
		}
	}
</script>
